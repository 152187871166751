import { db } from '@/firebase/config'

const getAlumniNum = async (alumniNum, companyId) => {
  try {
    await db.collection("Companies").doc(companyId).get().then(doc => {
      alumniNum.value = doc.data().alumniNum
    })
  } catch (error) {
    alert("getAlumniNum:" + error)
  }

};

export default getAlumniNum
