import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

const getWishesForOneFirm = async (store, companyId) => {
  try {
    store.value = [];
		let promises = [];
		let docRef, temp;
		docRef = db.collection("Companies").doc(companyId).collection("Wishes");
		
    await docRef.orderBy("candidateCount","desc").get().then(docs => {
      docs.forEach(doc => {
				temp = Promise.resolve((async () => {
					let candidates = [];
					await doc.ref.collection("Candidates").get().then(docs => {
						docs.forEach(doc => {
							if (doc.exists){
								candidates.push({...doc.data(), candidateId: doc.id});
							}
						})
					});
					store.value.push({...doc.data(), wishId: doc.id, candidates: JSON.parse(JSON.stringify(candidates))});
				})());
				promises.push(temp);
			});
		});
		await Promise.all(promises);
    //console.log("getWishesForOneFirm activated");
  } catch (error) {
    alert("getWishesForOneFirm:" + error);
  }

};

export default getWishesForOneFirm
