<template>
  <div class="filter">
    <div class="filter-major">
      <h4 class="filter-titles">States</h4>
      <select
        name="filter-major-selections"
        id="filter-major-selections"
        v-model="state"
      >
        <option value="" @click="state = null">All States</option>
        <option value="AL">Alabama, AL</option>
        <option value="AK">Alaska, AK</option>
        <option value="AZ">Arizona, AZ</option>
        <option value="AR">Arkansas, AK</option>
        <option value="CA">California, CA</option>
        <option value="CO">Colorado, CO</option>
        <option value="CT">Connecticut, CT</option>
        <option value="DE">Delaware, DE</option>
        <option value="FL">Florida, FL</option>
        <option value="Foreign">Foreign</option>
        <option value="GA">Georgia, GA</option>
        <option value="HI">Hawaii, HI</option>
        <option value="ID">Idaho, ID</option>
        <option value="IL">Illinois, IL</option>
        <option value="IN">Indiana,IN</option>
        <option value="IA">Iowa, IA</option>
        <option value="KS">Kansas, KS</option>
        <option value="KY">Kentucky, KY</option>
        <option value="LA">Louisiana, LA</option>
        <option value="ME">Maine, ME</option>
        <option value="MD">Maryland, MD</option>
        <option value="MA">Massachusetts, MA</option>
        <option value="MI">Michigan, MI</option>
        <option value="MN">Minnesota, MN</option>
        <option value="MS">Mississippi, MS</option>
        <option value="MO">Missouri, MO</option>
        <option value="MT">Montana, MT</option>
        <option value="NE">Nebraska, NE</option>
        <option value="NV">Nevada, NV</option>
        <option value="NH">New Hampshire, NH</option>
        <option value="NJ">New Jersey, NJ</option>
        <option value="NM">New Mexico, NM</option>
        <option value="NY">New York, NY</option>
        <option value="NC">North Carolina, NC</option>
        <option value="ND">North Dakota, ND</option>
        <option value="OH">Ohio, OH</option>
        <option value="OK">Oklahoma, OK</option>
        <option value="OR">Oregon, OR</option>
        <option value="PA">Pennsylvania, PA</option>
        <option value="RI">Rhode Island, RI</option>
        <option value="SC">South Dakota, SD</option>
        <option value="TN">Tennessee, TN</option>
        <option value="TX">Texas, TX</option>
        <option value="UT">Utah, UT</option>
        <option value="VT">Vermont, VT</option>
        <option value="VA">Virginia, VA</option>
        <option value="WA">Washington, WA</option>
        <option value="WV">West Virginia, WV</option>
        <option value="WI">Wisconsin, WI</option>
        <option value="WY">Wyoming, WY</option>
      </select>
    </div>
    <div class="filter-position">
      <h4 class="filter-titles">Position Key Word</h4>
      <input class="filter-gpa-input" type="text" v-model="position" />
    </div>
    <div class="filter-status" v-if="isForHome">
      <h4 class="filter-titles">Status</h4>
      <select
        id="filter-status-selections"
        v-model="status"
        multiple
      >
        <option value="" @click="status = null">All Status</option>
        <option value="pending">Pending</option>
        <option value="granted">Granted</option>
        <option value="expired">Expired</option>
      </select>
    </div>
    <div class="filter-position-mobile-only">
      <h4 class="filter-titles">Position Key Word</h4>
      <input class="filter-gpa-input" type="text" v-model="position" />
    </div>
    <div class="filter-fillEmpty" v-if="!isForHome"></div>
    <div class="filter-clear">
      <button class="apply-btn" @click="handleApply(false)">
        <h4>Clear Filters</h4>
      </button>
    </div>
    <div class="filter-apply">
      <button class="apply-btn" @click="handleApply(true)">
        <h4>Apply Filters</h4>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: ["isForHome"],
  emits: ["apply-filters"],
  setup(props, { emit }) {
    const state = ref(null);
    const position = ref(null);
    const status = ref(null);
    // const hasExp = ref(false);

    const handleApply = (flag) => {
      if (flag){
        emit("apply-wish-filters", {
          state: state.value,
          position: position.value,
          status: status.value
        });
      } else {
        state.value = null;
        position.value = null;
        status.value = null;
        emit("apply-wish-filters", {
          state: null,
          position: null,
          status: null
        });
      }
    };

    return { state, position, status, handleApply };
  },
};
</script>

<style scoped>
.filter {
  border: 2px var(--purple) solid;
  border-radius: 1vh;
  padding: 1.5vh 1.5vw;
  margin: 2vh 0;
  display: flex;
  flex-wrap: wrap;
  animation: fadein 0.5s;
  transform: scaleY(1);
  transform-origin: top;
  transition: all 0.25s ease;
  overflow: hidden;
}

.filter-major {
  flex: 0 1 20%;
}

.filter-position {
  flex: 0 1 20%;
}

.filter-status,
.filter-fillEmpty {
  flex: 0 1 20%;
}

.filter-clear,
.filter-apply {
  display: flex;
  align-items: center;
  flex: 0 1 20%;
}

.filter-titles {
  margin-bottom: 0.5vh;
  white-space: nowrap;
}

@media screen and (max-width: 800px) {
  .filter-major,
  .filter-gpa,
  .filter-exp,
  .filter-apply {
    flex: 0 1 50%
  }

  .filter-major h4,
  .filter-gpa h4,
  .filter-exp h4,
  .filter-apply h4 {
    font-size: 95%;
  }
}

#filter-major-selections,
#filter-status-selections {
  overflow-y: scroll;
  width: 85%;
  border: 2px var(--purple) solid;
  border-radius: 1vh;
}

#filter-major-selections option,
#filter-status-selections option {
  padding: 0.3vh 0.5vw;
  font-size: 120%;
}

.filter-gpa-input,
.filter-gpa-input:focus {
  width: 85%;
  border: 2px var(--purple) solid;
  font-size: 110%;
  padding: 0.3vh 0.5vw;
  border-radius: 1vh;
}

#filter-major-selections,
#filter-status-selections,
.filter-gpa-input,
.filter-gpa-input:focus {
  height: 4vh;
}

.filter-exp label {
  font-weight: bold;
}

.filter-exp input {
  position: absolute;
  cursor: pointer;
  top: 2.75vh;
  left: 0.5vw;
  height: 0;
  width: 0;
}

.filter-exp span {
  top: 2.5vh !important;
}

.filter-exp input:checked ~ .checkmark {
  background-color: var(--purple);
  color: white;
}

/* Show the indicator (dot/circle) when checked */
.filter-exp input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.filter-exp .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.apply-btn {
  margin: auto;
  height: 5vh;
  padding: 1vh;
  color: var(--purple-l);
  background-color: white;
  border: 2px var(--purple-l) solid;
  border-radius: 1vh;
}

.apply-btn:hover {
  background-color: var(--purple-l);
  color: white;
  border-color: var(--purple-l);
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-position-mobile-only {
  display: none;
}

@media screen and (max-width: 600px) {
  .filter-clear,
  .filter-apply {
    flex-basis: 50%;
  }

  .filter-status,
  .filter-major {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .filter-position {
    display: none;
  }

  .filter-position-mobile-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
    margin-bottom: 10px;
  }

  .filter-gpa-input, .filter-gpa-input:focus {
    width: 85%;
  }

  .filter-fillEmpty {
    display: none;
  }
}
</style>
